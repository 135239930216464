@import './colors.scss';

body {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 1.2rem !important;
    font-weight: 300 !important;
    background-color: $background !important;
    color: $foreground !important;

    //backend
    #CMS_panel {
        position: relative;
    }

    .header-container {
        background-color: $menu-background;
        width: 100%;
        max-width: unset;
    }

    .bp-header {
        padding: 0;
        margin-bottom: 0;
        max-width: unset;

        #nav-toggle {
            padding: 20px 30px;
            color: $foreground-menu !important;
        }

        nav {
            a {
                color: $foreground-menu;
                font-family: 'Raleway';
                font-weight: lighter;
                text-transform: uppercase;
                font-size: 1em;
                display: inline-block;
                position: relative;
                padding: 5px 7px;
                margin-left: 10px;

                @media(max-width: 767px) {
                    color: #ffffff;
                    display: block;
                }
            }

            .selected {
                background-color: $menu-selected-background;
            }

            @media(max-width: 767px) {
                width: 100%;
            }
        }

        .mobilemenu {
            color: $foreground-menu !important;

            button {
                border: none;
                color: $foreground-menu !important;
            }
            button:hover {
                color: $foreground-menu !important;
            }
        }

        .logo {
            color: $foreground-menu;
            font-family: 'Raleway';
            font-size: 16px;
            margin: 0;
            padding: 17px 0 17px 30px;
            font-weight: 700;

            div:first-child {
                font-weight: 100;
                text-transform: uppercase;
            }
        }
        .logo:hover {
            text-decoration: none;
        }
    }

    > div[data-apos-refreshable] {
        min-height: 500px;
    }

    h1, h2, h3, h4 {
        padding: 20px 10px 0;
        font-family: 'Raleway';
        font-size: 1.5em;
    }

    td {
        border: none !important;
    }

    a {
        color: $link;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            color: $link;
            text-decoration: none;
            font-weight: bolder !important;
        }
    }

    .bp-image-widget {
        border-radius: 5px;
    }

    .seminare .bp-image-widget {
        margin-top: 25px;
    }

    .bp-main {
        min-height: calc(100vh - 160px);
        max-width: unset;

        .container {
            max-width: 920px;
        }

        .home {
            max-width: unset;
            min-width: 100%;
            padding: 0;
            margin: 0;
            overflow-x: hidden;
            background-color: $background-home !important;
            color: $foreground-home !important;

            img {
                max-width: unset;
                min-height: calc(100vh - 80px);
                min-width: 100vw;
                border-radius: 0;

                // landscape
                @media (max-width: 1240px) and (orientation: portrait) {
                    margin-left: max(100vw - 1240px, -260px);
                }

                // portait
                @media (max-height: 1500px) {
                    // margin-top: max(calc(100vh - 1500px), -420px);
                }

                & ~ .bp-rich-text:first-of-type {
                    margin-top: -83px;

                    &::after {
                        content: "";
                        display: block;
                        max-width: 100vw;
                        width: 100%;
                        height: 90px;
                        margin-top: -126px;
                        background-color: rgba(255, 255, 255, 0.7);
                        background-image: url('/images/Logo.png');
                        background-repeat: no-repeat;
                        background-position-y: 7px;
                        background-position-x: center;
                        background-size: contain;
                        position: absolute;
                    }

                    p {
                        padding-top: 10px;
                    }
                }
            }

            .bp-rich-text, .bp-rich-text {
                background-color: rgba(255, 255, 255, 0.86);
                color: $foreground;
                padding: 0 0 10px 0;
                position: relative;
                max-width: 100vw;

                p {
                    margin: 0 auto;
                    line-height: 1.4 !important;
                }
            }

            .three-column {
                margin-bottom: 20px;

                .bp-rich-text {
                    background-color: unset;
                    color: unset;
                    max-width: unset;
                    margin-top: 30px;
                }
            }
        }

        .seminare, .kontakt {
            .two-column, .three-column {
                margin: 50px auto;
            }
        }

        .preise, .leistungen {
            .column {
                margin: 50px auto;
            }
        }
    }

    .bp-content {
        max-width: unset;
        padding: 0 30px;
    }

    .sidemenu {
        background: none;
        margin: 2em 0;

        a {
            color: $foreground;
            display: block;
            margin-bottom: 2px;

            &.selected {
                color: $foreground;
                font-weight: 700;
            }

            svg {
                margin-right: 5px;
            }
        }
    }

    .wc101body2, .wc102body1 {
        border: none !important;
    }

    .home {
        .bp-welcome__area {
            margin-top: 0;
        }
    }

    .footer-container {
        width: 100%;
        background-color: $footer;
        max-width: unset;
        text-align: center;

        a {
            color: $footer-link;
        }
    }

    footer.bp-footer {
        width: 100%;
        max-width: none;
        padding: 40px 20px;
    }

    .visible-sm {
        @media (min-width: 768px) {
            display: none;
        }
    }

    .hidden-sm {
        @media (max-width: 767px) {
            display: none;
        }
    }
}
