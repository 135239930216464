$dark-grey: #222222;
$grey: #a19c9c;
$dark-petrol: #214d54;
$petrol: #419aa8;
$light-petrol: #aad2d8;
$white: #ffffff;
$dark-blue: rgb(5, 5, 68);

$background-home: $dark-petrol;
$foreground-home: $white;
$background: $light-petrol;
$foreground: $dark-petrol;
$foreground-menu: $white;
$menu-background: $petrol;
$menu-selected-background: $light-petrol;
$link: $petrol;
$footer: $petrol;
$footer-link: $white;
