.bp-welcome {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

}

.bp-welcome__area {
  margin-top: 40px;
}

.bp-welcome__headline {
  position: relative;
  margin: 0 0 50px;
}

.bp-welcome__verson-wrapper {
  display: flex;
  justify-content: center;
}

.bp-welcome__version {
  position: relative;
  background-color: #a992ff;
  padding: 4px 5px;
  border-radius: 2px;
  display: inline-block;
  color: #300ab7;
  font-size: 12px;
  box-shadow: 0 25px 50px rgba(64,70,104,.1);
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.bp-welcome__help {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 24px;
}

.bp-welcome p {
  max-width: 500px;
  margin: 20px auto;
  line-height: 1.5;
  font-size: 1.1rem;
}

.bp-welcome__code__context {
  position: absolute;
  display: inline-block;
  bottom: auto;
  left: auto;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  text-transform: uppercase;
  font-size: 11px;
  font-family: code-saver,sans-serif;
  letter-spacing: .025em;
  padding: 5px 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-top-right-radius: 2px;
  background: #ffffff20;
  color: #ffffff96;
  letter-spacing: 1px;
}

.bp-welcome__code {
  margin: 40px auto;
}

p.bp-welcome__cta {
  text-align: center;
  margin-bottom: 40px;
}

.bp-mode {
  display: inline-block;
  padding: 5px 5px;
  font-size: 12px;
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  line-height: 10px;
  color: black;
  vertical-align: middle;
  background-color: white;
  border-bottom-color: black;
  border: 1px solid black;
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 black;
}