body {
  font-family: "Open Sans", sans-serif !important;
  font-size: 1.2rem !important;
  font-weight: 300 !important;
  background-color: #aad2d8 !important;
  color: #214d54 !important;
}
body #CMS_panel {
  position: relative;
}
body .header-container {
  background-color: #419aa8;
  width: 100%;
  max-width: unset;
}
body .bp-header {
  padding: 0;
  margin-bottom: 0;
  max-width: unset;
}
body .bp-header #nav-toggle {
  padding: 20px 30px;
  color: #ffffff !important;
}
body .bp-header nav a {
  color: #ffffff;
  font-family: "Raleway";
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 1em;
  display: inline-block;
  position: relative;
  padding: 5px 7px;
  margin-left: 10px;
}
@media (max-width: 767px) {
  body .bp-header nav a {
    color: #ffffff;
    display: block;
  }
}
body .bp-header nav .selected {
  background-color: #aad2d8;
}
@media (max-width: 767px) {
  body .bp-header nav {
    width: 100%;
  }
}
body .bp-header .mobilemenu {
  color: #ffffff !important;
}
body .bp-header .mobilemenu button {
  border: none;
  color: #ffffff !important;
}
body .bp-header .mobilemenu button:hover {
  color: #ffffff !important;
}
body .bp-header .logo {
  color: #ffffff;
  font-family: "Raleway";
  font-size: 16px;
  margin: 0;
  padding: 17px 0 17px 30px;
  font-weight: 700;
}
body .bp-header .logo div:first-child {
  font-weight: 100;
  text-transform: uppercase;
}
body .bp-header .logo:hover {
  text-decoration: none;
}
body > div[data-apos-refreshable] {
  min-height: 500px;
}
body h1, body h2, body h3, body h4 {
  padding: 20px 10px 0;
  font-family: "Raleway";
  font-size: 1.5em;
}
body td {
  border: none !important;
}
body a {
  color: #419aa8;
  cursor: pointer;
  text-decoration: none;
}
body a:hover {
  color: #419aa8;
  text-decoration: none;
  font-weight: bolder !important;
}
body .bp-image-widget {
  border-radius: 5px;
}
body .seminare .bp-image-widget {
  margin-top: 25px;
}
body .bp-main {
  min-height: calc(100vh - 160px);
  max-width: unset;
}
body .bp-main .container {
  max-width: 920px;
}
body .bp-main .home {
  max-width: unset;
  min-width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-color: #214d54 !important;
  color: #ffffff !important;
}
body .bp-main .home img {
  max-width: unset;
  min-height: calc(100vh - 80px);
  min-width: 100vw;
  border-radius: 0;
}
@media (max-width: 1240px) and (orientation: portrait) {
  body .bp-main .home img {
    margin-left: max(100vw - 1240px, -260px);
  }
}
body .bp-main .home img ~ .bp-rich-text:first-of-type {
  margin-top: -83px;
}
body .bp-main .home img ~ .bp-rich-text:first-of-type::after {
  content: "";
  display: block;
  max-width: 100vw;
  width: 100%;
  height: 90px;
  margin-top: -126px;
  background-color: rgba(255, 255, 255, 0.7);
  background-image: url("/images/Logo.png");
  background-repeat: no-repeat;
  background-position-y: 7px;
  background-position-x: center;
  background-size: contain;
  position: absolute;
}
body .bp-main .home img ~ .bp-rich-text:first-of-type p {
  padding-top: 10px;
}
body .bp-main .home .bp-rich-text, body .bp-main .home .bp-rich-text {
  background-color: rgba(255, 255, 255, 0.86);
  color: #214d54;
  padding: 0 0 10px 0;
  position: relative;
  max-width: 100vw;
}
body .bp-main .home .bp-rich-text p, body .bp-main .home .bp-rich-text p {
  margin: 0 auto;
  line-height: 1.4 !important;
}
body .bp-main .home .three-column {
  margin-bottom: 20px;
}
body .bp-main .home .three-column .bp-rich-text {
  background-color: unset;
  color: unset;
  max-width: unset;
  margin-top: 30px;
}
body .bp-main .seminare .two-column, body .bp-main .seminare .three-column, body .bp-main .kontakt .two-column, body .bp-main .kontakt .three-column {
  margin: 50px auto;
}
body .bp-main .preise .column, body .bp-main .leistungen .column {
  margin: 50px auto;
}
body .bp-content {
  max-width: unset;
  padding: 0 30px;
}
body .sidemenu {
  background: none;
  margin: 2em 0;
}
body .sidemenu a {
  color: #214d54;
  display: block;
  margin-bottom: 2px;
}
body .sidemenu a.selected {
  color: #214d54;
  font-weight: 700;
}
body .sidemenu a svg {
  margin-right: 5px;
}
body .wc101body2, body .wc102body1 {
  border: none !important;
}
body .home .bp-welcome__area {
  margin-top: 0;
}
body .footer-container {
  width: 100%;
  background-color: #419aa8;
  max-width: unset;
  text-align: center;
}
body .footer-container a {
  color: #ffffff;
}
body footer.bp-footer {
  width: 100%;
  max-width: none;
  padding: 40px 20px;
}
@media (min-width: 768px) {
  body .visible-sm {
    display: none;
  }
}
@media (max-width: 767px) {
  body .hidden-sm {
    display: none;
  }
}